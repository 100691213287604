import React from 'react'

import SEO from 'src/components/seo'
import { Trans, withTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import { IN_UK } from '../utils/constants'

import {
  Container,
  Column,
  Line,
  CurrentPage,
  PageLink,
  Subtitle,
  Text,
  ALink,
  SubHeader,
  PageTitle,
  Highlight,
  ListItem,
  PrivacyOrderedList,
  Table,
  ScrollDiv,
  OverflowContainer,
} from '../templates/ShopifyPage/styles'

const PrivacyPolicy = () => (
  <>
    <SEO
      title="Privacy Policy"
      description="The Skylight Frame (Glimpse LLC) Privacy Policy."
      path="/privacy-policy/"
    />
    <Container>
      <Column>
        <div>
          <Line />
          <CurrentPage>
            <Trans>Privacy Policy</Trans>
          </CurrentPage>
          <Line />
          <PageLink to="/tos/">
            <Trans>
              <Trans>Terms of Service</Trans>
            </Trans>
          </PageLink>
          <Line />
          {IN_UK && (
            <>
              <PageLink to="/psti-compliance/">
                <Trans>PSTI Statement of Compliance</Trans>
              </PageLink>
              <Line />
            </>
          )}
        </div>
      </Column>
      <OverflowContainer>
        <PageTitle>
          <Trans>Skylight Privacy Policy</Trans>
        </PageTitle>
        <Subtitle>
          <Trans i18nKey="auto_translate_privacy" />
          <br />
          <Trans>Last modified October 24, 2022</Trans>
        </Subtitle>
        <Text>
          <Trans>
            Thank you for visiting this website! At Glimpse LLC, doing business as Skylight
            ("Skylight", "we", "us" and "our"), we are committed to protecting the privacy of
            everyone who visits our website
          </Trans>{' '}
          (<ALink href="http://www.skylightframe.com">www.skylightframe.com</ALink>){' '}
          <Trans>
            ("Websites") as well as the privacy of any customers purchasing or using our products
            and/or services.
          </Trans>
        </Text>
        <Text>
          <Trans>
            This privacy policy ("Privacy Policy") describes how we collect, use, store, share and
            disclose your personal data and applies to your use of our Websites, our mobile
            applications, our digital frames, smart calendars, and other products and services that
            we provide (collectively referred to here as the "Services"). "Personal Data" means
            information about you which can be used, on its own or together with other information,
            to identify you as a person and includes but is not limited to name, email address,
            billing address, shipping address, and phone number. Personal Data can also include
            information about any transactions, both free and paid, that you enter into on the
            Websites, and information about you that is available on the internet, such as from
            Facebook, LinkedIn, Twitter and Google, or publicly available information that we
            acquire from service providers. We also may obtain Personal Data when you choose to link
            third-party services with our Services, such as auto-syncing with your online calendars.
          </Trans>
        </Text>
        <Text>
          <Trans>
            If you are visiting this Website from the European Union, the United Kingdom, or
            elsewhere in the European Economic Area, please see the included Supplemental EEA
            Privacy Statement. Additional information is also provided for California residents
            below.
          </Trans>
        </Text>
        <Text>
          <Trans>
            By accepting our Privacy Policy and the Terms and Conditions, you are acknowledging that
            you have read and understood the policy. If you choose to use our Services, then by
            doing so you agree to the collection and use of information as described in this Privacy
            Policy.
          </Trans>
        </Text>
        <SubHeader>
          <Trans>Data We Collect and How We Use It</Trans>
        </SubHeader>
        <Text>
          <Trans>
            Skylight collects Personal Data through various means, including voluntary collection
            such as when you provide information to us yourself in filling out a form, and also
            automatically when you access, use, or interact with the Services. Our main reason for
            processing Personal Data is to operate, maintain, enhance, and provide to you the
            features of the Services you use. In some instances, the amount of information is
            voluntary but, in some instances, certain information will be required for you to use
            and for us to provide you the Services. The Personal Data we request is context driven
            and the exact data we collect will depend on how you interact with us and which Service
            you use.
          </Trans>
        </Text>
        <Text>
          <Trans>Your address or other Personal Data we collect may be used to:</Trans>
        </Text>
        <PrivacyOrderedList>
          <ListItem>
            <Trans>
              Provide you with the Services and monitoring what Services you have purchased; or
            </Trans>
          </ListItem>
          <ListItem>
            <Trans>Contact you for administrative purposes such as customer support; or</Trans>
          </ListItem>
          <ListItem>
            <Trans>Reply to inquiries via a customer support request; or</Trans>
          </ListItem>
          <ListItem>
            <Trans>
              Send communications, including updates on promotions and events, relating to products
              and services offered by us, if you have provided your consent and not withdrawn it.
            </Trans>
          </ListItem>
        </PrivacyOrderedList>
        <Text>
          <Trans>We use the information that we collect on the Service to:</Trans>
        </Text>
        <PrivacyOrderedList>
          <ListItem>
            <Trans>
              Understand and analyze the usage trends and preferences of our users; and/or
            </Trans>
          </ListItem>
          <ListItem>
            <Trans>
              Improve the Service, and to develop new products, services, feature, and
              functionality; and/or
            </Trans>
          </ListItem>
          <ListItem>
            <Trans>To investigate any illegal activity such as unauthorized access or fraud.</Trans>
          </ListItem>
        </PrivacyOrderedList>
        <SubHeader>
          <Trans>Photographs and Videos</Trans>
        </SubHeader>
        <Text>
          <Trans>
            Photo and video content you provide is stored on servers with our secure cloud hosting
            provider, and we use reliable modern security practices to protect your privacy. Photos
            and videos are sent to your Skylight device using an encrypted protocol and are not
            accessible to any other party. We will never share your content unless you give us clear
            instructions through the Services to do so, such as when you select family members or
            others to receive content on their frames.
          </Trans>
        </Text>
        <SubHeader>
          <Trans>Third-Party Services</Trans>
        </SubHeader>
        <Text>
          <Trans>
            If you choose to use or link our Services with third-party services, such as synching
            your online accounts with our smart family calendar, or using an Amazon Fire device with
            our Skylight Digital app, we will use information we collect to authenticate you,
            provide the Services you request, and we will transmit data to and from the third-party
            service you wish to use with our Skylight Services based on your selections.
          </Trans>
        </Text>
        <Text>
          <Trans>
            Third parties whose services you use in connection with ours, or third parties whose
            websites we link to from our Websites, will have data information practices that are
            different from ours. This Skylight Privacy Policy does not apply to the activities of
            third parties when they are collecting or using data from you for their own purpose or
            on behalf of others. We cannot control and are not responsible for the activities of
            these third parties. We encourage you to review their privacy policies to understand how
            they use your information.
          </Trans>
        </Text>
        <SubHeader>
          <Trans>Log Data and Analytics</Trans>
        </SubHeader>
        <Text>
          <Trans>
            Whenever you use our Services, we collect data and information made available on your
            device called Log Data. This Log Data may include information such as your device
            Internet Protocol ("IP") address, device name, operating system version, the
            configuration of our mobile app when utilizing our Services, the time and date of your
            use of the Service, and other statistics.
          </Trans>
        </Text>
        <Text>
          <Trans>
            We also have access to analytics and data from your device regarding when the Services
            have been used and how they were used. This means that when you view content provided by
            us, we automatically collect information about your computer hardware and software. This
            information can include your IP-address, browser type, domain names, internet protocol
            services (ISP), the files viewed on our site operating system, access time, etc.
          </Trans>
        </Text>
        <Text>
          <Trans>
            This information is used by Skylight to log general statistics about the Websites and
            provide insights to help improve our user experience and product offerings.
          </Trans>
        </Text>
        <SubHeader>
          <Trans>Cookies and Analytics Information</Trans>
        </SubHeader>
        <Text>
          <Trans>
            We may use various technical mechanisms such as cookies, web beacons, and similar
            tracking technologies to monitor how users use our Website and mobile apps to better
            understand customer behavior and improve our Services. Cookies are files with a small
            amount of data that are commonly used as anonymous unique identifiers. These are sent to
            your browser from the websites that you visit and are stored on your device's internal
            memory.
          </Trans>
        </Text>
        <Text>
          <Trans>
            You can delete and disable all cookies through settings in your Internet browser,
            however if you choose to do so, some components of our Services may not work properly if
            cookies necessary for operation are blocked.
          </Trans>
        </Text>
        <SubHeader>
          <Trans>When We Disclose Data</Trans>
        </SubHeader>
        <Text>
          <Trans>
            Except as described in this Privacy Policy, we will not disclose Personal Data that we
            collect on the Services to third parties without your consent.
          </Trans>
        </Text>
        <Text>
          <Trans>
            We may disclose information to third parties in the following circumstances:
          </Trans>
        </Text>
        <Text>
          <Highlight>
            <Trans>Third-Party Vendors. </Trans>
          </Highlight>
          <Trans>
            We use outside shipping companies to ship orders, and a payment card processing company
            to securely bill users for our Services. We share order data such as name, shipping
            address, billing address with our shipping company. However, we do not retain or store
            any credit card payment information as the payments go directly through our payment
            providers. We also use trusted third-party hosting and infrastructure providers to
            securely provide the Services to you.
          </Trans>
        </Text>
        <Text>
          <Trans>
            These vendor companies do not retain, share, store or use Personal Data for any
            secondary purposes beyond fulfilling your order and helping us provide the Services to
            you.
          </Trans>
        </Text>
        <Text>
          <Highlight>
            <Trans>Third-Party Services. </Trans>
          </Highlight>
          <Trans>
            As noted above, if you choose to link our Services with a third-party service such as
            your online calendar, we will provide information to that third party based on your
            settings in our Services in order to interoperate with the third-party service.
          </Trans>
        </Text>
        <Text>
          <Highlight>
            <Trans>We partner with third parties to manage our advertising on other sites. </Trans>
          </Highlight>
          <Trans>
            From time to time, we may confidentially share email addresses with our partners for ad
            targeting. Our third-party partners may use cookies or similar technologies to provide
            you with advertising based upon your browsing activities and interests. They will never
            email you for any reason. We never sell your Personal Data to any third party.
          </Trans>
        </Text>
        <Text>
          <Highlight>
            <Trans>When required by law or necessary to avoid harm. </Trans>
          </Highlight>
          <Trans>
            We may share your information if we are required to do so by law or in the good-faith
            belief that such action is necessary to comply with applicable laws, to protect our
            property, to prevent fraud, or in response to a court order, judicial or other
            government subpoena, or to otherwise cooperate with law enforcement or other
            governmental agencies. This may include sharing information with other companies,
            lawyers, agents, or government agencies.
          </Trans>
        </Text>
        <Text>
          <Trans>
            We may also share your information if we believe it is necessary to protect you or
            others from harm, such as fraud or abuse, or as needed to establish our legal rights.
          </Trans>
        </Text>
        <Text>
          <Highlight>
            <Trans>Corporate Events. </Trans>
          </Highlight>
          <Trans>
            If Skylight (or our assets related to our business) are acquired by another company,
            whether by merger, acquisition, or otherwise, that company will receive all information
            gathered by Skylight on the Websites and Service. In this event, you will be notified
            via email and/or a prominent notice on our Website of any change in ownership, uses of
            your Personal Data, and choices you may have regarding your Personal Data.
          </Trans>
        </Text>
        <SubHeader>
          <Trans>Security</Trans>
        </SubHeader>
        <Text>
          <Trans>
            We value your trust in providing us your Personal Data. We implement appropriate
            physical, technical and organizational security measures to protect Personal Data
            against accidental or unlawful destruction, accidental loss or alteration, unauthorized
            disclosure or access, and against all other unlawful forms of use, in accordance with
            industry standards. Despite our efforts we want to remind you that no method of
            transmission over the internet, or method of electronic storage, is 100% secure and
            reliable, and we cannot guarantee its absolute security. It is important that you take
            steps to protect your information as well, such as protecting your passwords.
          </Trans>
        </Text>
        <Text>
          <Trans>
            We do our very best to make sure that our personnel and trusted hosting and other
            service providers who need the information to perform a specific job are granted access
            to the Personal Data only on a need-to-know basis and are subject to stringent
            confidentiality requirements.
          </Trans>
        </Text>
        <SubHeader>
          <Trans>Children's Privacy</Trans>
        </SubHeader>
        <Text>
          <Trans>
            Our Services are not designed or intended for use by anyone under the age of 13, and you
            may not use the Services if you are under the age of 13. As such, we do not knowingly
            collect personal information from children under 13. If you are a parent or guardian and
            you are aware that your child has provided us with personal information, please contact
            us so that we will be able to take the necessary actions.
          </Trans>
        </Text>
        <SubHeader>
          <Trans>Supplemental EEA Privacy Statement</Trans>
        </SubHeader>
        <Text>
          <Trans>
            This Supplemental EEA Privacy Statement is relevant to any individual located in the
            European Economic Area ("EEA") who uses the Services. Applicable law, including the
            European Union Regulation 2016/679 of 27 April 2016 on the protection of natural persons
            with regard to the processing of personal data and on the free movement of such data
            ("GDPR"), may require Skylight to provide additional and different information about its
            data processing practices to data subjects in the EEA. If you are accessing the Services
            from a member state of the EEA through a site targeting an EEA member country, then this
            Supplemental EEA Privacy Statement applies to you.
          </Trans>
        </Text>
        <Text>
          <Trans>
            For purposes of the GDPR and other applicable laws, we, Glimpse LLC, with offices at
            101A Clay St #144, San Francisco, California 94111, USA, are the data controller of your
            personal information that we collect. Where the processing of personal information is
            undertaken by our affiliates, subsidiaries, or related entities, they are a joint
            controller with Skylight.
          </Trans>
        </Text>
        <SubHeader>
          <Trans>Legal Basis for Processing Personal Data</Trans>
        </SubHeader>
        <Text>
          <Highlight>
            <Trans>Personal Data Transfers outside of the EEA. </Trans>
          </Highlight>
          <Trans>
            Skylight may transmit some of your Personal Data to a country where the data protection
            laws may not provide a level of protection equivalent to the laws in your jurisdiction,
            including the United States. As required by applicable law, Skylight will provide an
            adequate level of protection for your Personal Data using various means, including,
            where appropriate:
          </Trans>
        </Text>
        <PrivacyOrderedList>
          <ListItem>
            <Trans>
              relying on a formal decision by the European Commission that a certain country ensures
              an adequate level of protection for personal data (a full list of such decisions may
              be accessed online here:
            </Trans>{' '}
            <ALink
              href="hhttps://ec.europa.eu/info/law/law-topic/data-protection_en"
              target="_blank"
            >
              https://ec.europa.eu/info/law/law-topic/data-protection_en
            </ALink>
            )
          </ListItem>
          <ListItem>
            <Trans>
              entering into appropriate data transfer agreements based on language approved by the
              European Commission, such as the most recent Standard Contractual Clauses, which may
              be accessed online here:
            </Trans>{' '}
            <ALink
              href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en"
              target="_blank"
            >
              https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en
            </ALink>
          </ListItem>
          <ListItem>
            <Trans>
              implementing appropriate physical, technical and organizational security measures to
              protect Personal Data against accidental or unlawful destruction, accidental loss or
              alteration, unauthorized disclosure or access, and against all other unlawful forms of
              processing;
            </Trans>
          </ListItem>
          <ListItem>
            <Trans>
              taking other measures to provide an adequate level of data protection in accordance
              with applicable law.
            </Trans>
          </ListItem>
        </PrivacyOrderedList>
        <Text>
          <Trans>
            Any onward transfer is subject to appropriate onward transfer requirements as required
            by applicable law.
          </Trans>
        </Text>
        <Text>
          <Highlight>
            <Trans>Data Retention. </Trans>
          </Highlight>
          <Trans>
            Skylight keeps Personal Data for as long as required to provide the Services you have
            requested or registered for and to comply with applicable laws and record-keeping
            requirements.
          </Trans>
        </Text>
        <Text>
          <Highlight>
            <Trans>Data Subject Rights. </Trans>
          </Highlight>
          <Trans>
            You have a right to request from Skylight access to and rectification or erasure of your
            Personal Data or restriction of processing concerning you, as well as the right to data
            portability under the GDPR. You also have the right to object, on grounds relating to
            your situation, at any time to the processing of your Personal Data by us and we can be
            required to no longer process your Personal Data. In general, you have the right to
            object to our processing of your Personal Data for direct marketing purposes. If you
            have a right to object and you exercise this right, your Personal Data will no longer be
            processed for such purposes by us. You can exercise such rights by accessing the
            information in your account and privacy settings, if applicable, or submitting a request
            by email to
          </Trans>
          <ALink href="mailto:help@skylightframe.com"> help@skylightframe.com</ALink>
          <Trans i18nKey="privacy_stellen" />.
        </Text>
        <Text>
          <Trans>
            If you have provided consent for cookies that are not strictly necessary, or for direct
            marketing emails or other data processing based on your consent, you have the right to
            withdraw your consent at any time, without affecting the lawfulness of processing based
            on consent before its withdrawal. You have the right to lodge a complaint with a
            supervisory authority.
          </Trans>
        </Text>
        <Text>
          <Highlight>
            <Trans>Your Choices. </Trans>
          </Highlight>
          <Trans>
            You are not required to provide any Personal Data to Skylight, but if you do not provide
            any Personal Data at all to Skylight, we will not be able to provide the Services. If
            you provide only some of the personal information requested, the functionality of some
            Services designed to process certain information for you may not operate correctly.
          </Trans>
        </Text>
        <Text>
          <Highlight>
            <Trans>Profiling. </Trans>
          </Highlight>
          <Trans>
            Skylight does not use in connection with the Services automated decision-making,
            including profiling, in a way that produces legal effects concerning you or which
            significantly affects you.
          </Trans>
        </Text>
        <SubHeader>
          <Trans>International Transfers of Your Data</Trans>
        </SubHeader>
        <Text>
          <Trans>
            The personal information we collect is normally stored in secure hosting facilities in
            the United. Your personal information may be accessed by our personnel in other
            countries on a need-to-know basis through secure connections to respond to your support
            requests, and to provide other maintenance, communications, and development services.
          </Trans>
        </Text>
        <Text>
          <Trans>
            We will ensure that any transfer of personal information from countries in the European
            Economic Area ("EEA") to countries outside the EEA will be protected by appropriate
            safeguards, for example by using standard data protection clauses approved by the
            European Commission and the United Kingdom, or the use of binding corporate rules, or
            other legally accepted means.
          </Trans>
        </Text>
        <SubHeader>
          <Trans>Additional Information for California Residents</Trans>
        </SubHeader>
        <Text>
          <Highlight>
            <Trans>How We Source, Use, and Disclose Information for Business Purposes. </Trans>
          </Highlight>
          <Trans>
            The following chart details the categories of personal information we collect, the
            sources of such personal information, and how we use and share such information for
            business purposes.
          </Trans>
        </Text>
        <ScrollDiv>
          <Table>
            <div>
              <Trans>Categories of Personal Information Collected</Trans>
            </div>
            <div>
              <Trans>Sources of Personal Information</Trans>
            </div>
            <div>
              <Trans>Purposes for Use of Personal Information</Trans>
            </div>
            <div>
              <Trans>Disclosures of Personal Information for Business Purposes</Trans>
            </div>
            <div>
              <Trans>
                Contact information (e.g., name, email address, phone number, shipping address
                including state/province, country)
              </Trans>
            </div>
            <div>
              • <Trans>You</Trans>
              <br />• <Trans>Public sources</Trans>
              <br />• <Trans>Partners</Trans>
            </div>
            <div>
              • <Trans>To fulfill orders and provide the Services</Trans>
              <br />• <Trans>Communicate with you for administrative and other purposes</Trans>
              <br />• <Trans>Analyze use of and personalize the Services</Trans>
              <br />• <Trans>Improve the Services</Trans> <br />•{' '}
              <Trans>Provide security, prevent fraud, and for troubleshooting errors</Trans>
              <br />• <Trans>Comply with legal requirements</Trans>
            </div>
            <div>
              • <Trans>Hosting and other trusted service providers</Trans>
              <br />•{' '}
              <Trans>
                Law enforcement in the event of a lawful request or to others to prevent harm
              </Trans>
              <br />• <Trans>With entities in the event of a corporate transaction</Trans>
              <br />• <Trans>With your consent</Trans> <br />•{' '}
              <Trans>Third-party services you select to integrate with our Services</Trans>
            </div>
            <div>
              <Trans>
                Financial and transactional information (e.g., payment account information and
                purchase history)
              </Trans>
            </div>
            <div>
              • <Trans>You</Trans>
              <br />• <Trans>Payment processors</Trans>
            </div>
            <div>
              • <Trans>Provide the Services ordered</Trans>
              <br />• <Trans>Facilitate your payments</Trans>
              <br />• <Trans>Provide security, and prevent fraud and abuse</Trans>
              <br />• <Trans>Comply with legal requirements</Trans>
            </div>
            <div>
              • <Trans>Payment processors</Trans>
              <br />• <Trans>Law enforcement in the event of a lawful request</Trans>
              <br />• <Trans>With entities in the event of a corporate transaction</Trans>
              <br />• <Trans>With your consent</Trans>
            </div>
            <div>
              <Trans>
                Login credentials (e.g., your account name and password, information needed to link
                to third-party services you choose)
              </Trans>
            </div>
            <div>
              • <Trans>You</Trans>
            </div>
            <div>
              • <Trans>Authenticate you and authorized users of the Services</Trans>
              <br />• <Trans>Provide security, and prevent fraud and abuse</Trans>
            </div>
            <div>
              • <Trans>Third-party service providers as needed to interoperate</Trans>
              <br />• <Trans>Law enforcement in the event of a lawful request</Trans>
              <br />• <Trans>With entities in the event of a corporate transaction</Trans>
              <br />• <Trans>With your consent</Trans>
            </div>
            <div>
              <Trans>
                Device and online identifier information (e.g., IP address, browser type, operating
                system, general location inferred from IP address, and similar information)
              </Trans>
            </div>
            <div>
              • <Trans>You, through your device</Trans>
            </div>
            <div>
              • <Trans>Provide the Services</Trans>
              <br />• <Trans>Analyze use of and personalize the Services</Trans>
              <br />• <Trans>Improve the Services</Trans>
              <br />• <Trans>Provide security, and prevent fraud and abuse</Trans>
            </div>
            <div>
              • <Trans>Hosting and other trusted service providers</Trans>
              <br />• <Trans>Law enforcement in the event of a lawful request</Trans>
              <br />• <Trans>With entities in the event of a corporate transaction</Trans>
              <br />• <Trans>With your consent</Trans>
            </div>
            <div>
              <Trans>
                Service usage information (e.g., the dates and times you use the services, how you
                use the services, and the content you interact with on the services)
              </Trans>
            </div>
            <div>
              • <Trans>You, through your device and tracking technologies</Trans>
            </div>
            <div>
              • <Trans>Provide the Services and for billing purposes</Trans>
              <br />• <Trans>Analyze use of and personalize the Services</Trans>
              <br />• <Trans>Improve the Services</Trans> <br />•{' '}
              <Trans>Provide security, and prevent fraud and abuse</Trans>
              <br />• <Trans>Comply with legal requirements</Trans>
            </div>
            <div>
              • <Trans>Third-party service providers as needed to interoperate</Trans>
              <br />• <Trans>Law enforcement in the event of a lawful request</Trans> <br />•{' '}
              <Trans>With entities in the event of a corporate transaction</Trans>
              <br />• <Trans>With your consent</Trans>
            </div>
            <div>
              <Trans>Your content (e.g, photos, videos, calendar information)</Trans>
            </div>
            <div>
              •{' '}
              <Trans>You, through your device and your account(s) with third-party services</Trans>
            </div>
            <div>
              • <Trans>Provide the Services</Trans>
            </div>
            <div>
              • <Trans>Hosting and other trusted service providers</Trans>
              <br />• <Trans>Other persons you have authorized to access your frame</Trans>
              <br />• <Trans>Third-party service providers as needed to interoperate</Trans>
              <br />•{' '}
              <Trans>
                Law enforcement in the event of a lawful request or to others to prevent harm
              </Trans>
              <br />• <Trans>With entities in the event of a corporate transaction</Trans>
              <br />• <Trans>With your consent</Trans>
            </div>
          </Table>
        </ScrollDiv>
        <Text>
          <Highlight>
            <Trans>Your California Privacy Rights. </Trans>
          </Highlight>
          <Trans>
            If you are a California resident, the California Consumer Privacy Act (“CCPA”) allows
            you to make certain requests about your personal information. Specifically, the CCPA
            allows you to request us to:
          </Trans>
        </Text>
        <PrivacyOrderedList>
          <ListItem>
            <Trans>
              Inform you about the categories of personal information we collect or disclose about
              you; the categories of sources of such information; the business or commercial purpose
              for collecting your personal information; and the categories of third parties with
              whom we share/disclose personal information
            </Trans>
          </ListItem>
          <ListItem>
            <Trans>
              Provide access to and/or a copy of certain personal information we hold about you
            </Trans>
          </ListItem>
          <ListItem>
            <Trans>Delete certain personal information we have about you</Trans>
          </ListItem>
          <ListItem>
            <Trans>
              Provide you with information about the financial incentives that we offer to you, if
              any.
            </Trans>
          </ListItem>
        </PrivacyOrderedList>
        <Text>
          <Trans>
            The CCPA further provides you with the right not to be discriminated against (as
            provided for in applicable law) for exercising your rights under the statute. Please
            note that certain information may be exempt from such requests under California law. For
            example, we need certain information in order to provide our Services to you. We also
            will take reasonable steps to verify your identity before responding to a request for
            security purposes. In doing so, we may ask you for verification information so that we
            can match at least two verification points with information we maintain in our files
            about you. If we are unable to verify you through this method, we shall have the right,
            but not the obligation, to request additional information from you.
          </Trans>
        </Text>
        <Text>
          <Trans>
            If you would like further information regarding your legal rights under California law
            or would like to exercise any of them, or if you are an authorized agent making a
            request on a California consumer's behalf, please contact us at
          </Trans>{' '}
          <ALink href="mailto:help@skylightframe.com"> help@skylightframe.com</ALink>.
        </Text>
        <Text>
          <Trans>
            The CCPA provides certain rights if a company “sells” personal information, as such term
            is defined under the CCPA. We do not engage in activities that would be considered
            “sales” of your personal information under the CCPA.
          </Trans>
        </Text>
        <Text>
          <Highlight>
            <Trans>Shine the Light Disclosure. </Trans>
          </Highlight>
          <Trans>
            The California “Shine the Light” law gives residents of California the right under
            certain circumstances to request information from us regarding the manner in which we
            share certain categories of personal information (as defined in the Shine the Light law)
            with third parties for their direct marketing purposes. We do not share your personal
            information with third parties for their own direct marketing purposes without your
            affirmative consent.
          </Trans>
        </Text>
        <Text>
          <Highlight>
            <Trans>Browser “Do Not Track” Signals. </Trans>
          </Highlight>
          <Trans>
            Some web browsers may transmit “do-not-track” signals to a website. Because of
            differences in how web browsers incorporate and activate this feature, it is not always
            clear whether users intend for these signals to be transmitted, or whether they are
            aware of them. Because there currently is no industry standard concerning what, if
            anything, a service should do when they receive such signals, we currently do not take
            action in response to these signals.
          </Trans>
        </Text>
        <SubHeader>
          <Trans>Changes to This Privacy Policy</Trans>
        </SubHeader>
        <Text>
          <Trans>
            We may update this Privacy Policy from time to time and if we do, we'll post any changed
            on this page. These changes are effective immediately. If you continue to use our
            Websites and our Services after those changes are in in effect, you agree to the new
            policy. If the changes are significant, we may provide more prominent notice or get your
            consent, as required by law.
          </Trans>
        </Text>
        <SubHeader>
          <Trans>Account Information and Deletion</Trans>
        </SubHeader>
        <Text>
          <Trans>
            You may update, correct, or delete your Personal Data and preferences at any time by
            contacting us at
          </Trans>{' '}
          <ALink href="mailto:help@skylightframe.com">help@skylightframe.com</ALink>{' '}
          <Trans>
            . Please note that while any changes you make will be reflected in active user databases
            instantly or within a reasonable period of time, we may retain some information you
            submit for satisfaction of legal obligations, or where we otherwise reasonably believe
            that we have a legitimate reason to do so.
          </Trans>
        </Text>
        <SubHeader>
          <Trans>Contact Us</Trans>
        </SubHeader>
        <Text>
          <Trans>
            If you have any questions or suggestions about our Privacy Policy, do not hesitate to
            contact us at
          </Trans>{' '}
          <ALink href="mailto:help@skylightframe.com">help@skylightframe.com</ALink>{' '}
          <Trans>
            or write to us at Glimpse LLC, 101A Clay St #144, San Francisco, California 94111, USA.
          </Trans>
        </Text>
      </OverflowContainer>
    </Container>
  </>
)

export default withTranslation()(PrivacyPolicy)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["common", "privacy"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
